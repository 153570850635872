<template>
  <component :is="tag" :style="{textAlign: align}">
    <template v-if="content">
      {{ content }}
    </template>
    <span v-else style="opacity: 0.33">
      請輸入段落內容...
    </span>
  </component>
</template>

<script>
import ParagraphSetting from './ParagraphSetting.vue'

export default {
  props: {
    id: String,
    classes: String,
    content: String,
    align: String,
    tag: String
  },
  craft: {
    defaultProps: {
      id: '',
      classes: '',
      align: 'left',
      content: 'This is a paragraph section.',
      tag: 'p'
    },
    settings: {
      ParagraphSetting,
    }
  }
}
</script>

<style lang="scss" scoped>
h1, h2, h3, h4, h5, h6, p {
  outline: 1px dashed gray;
}
</style>
