<template>
  <div>
    <CInput :label="'ID'" size="sm" v-model="id" horizontal />
    <CInput :label="'Class'" size="sm" v-model="classes" horizontal />
  </div>
</template>

<script>
import { settingMixin } from '@v-craft/core'

export default {
  mixins: [settingMixin],
  computed: {
    id: {
      get() {
        return this.elementProps.id
      },
      set(id) {
        this.updateContent({id})
      }
    },
    classes: {
      get() {
        return this.elementProps.classes
      },
      set(classes) {
        this.updateContent({classes})
      }
    },
  },
  data () {
    return {
      SettingTitle: '圖層'
    }
  },
  methods: {
    updateContent(prop) {
      Object.keys(prop).forEach(key => {
        if (typeof prop[key] === 'string') {
          this.elementPropsSetter(prop)
        }
      })
    },
  }
}
</script>
