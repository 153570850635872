<template>
  <div>
    <h6>HTML區塊</h6>
    <hr>
    <CInput :label="'ID'" size="sm" v-model="id" horizontal />
    <CInput :label="'Class'" size="sm" v-model="classes" horizontal />
    <prism-editor class="my-editor" v-model="html" :highlight="highlighter" line-numbers />
  </div>
</template>

<script>
import { settingMixin } from '@v-craft/core'

// import Prism Editor
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css' // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism-tomorrow.css' // import syntax highlighting styles

export default {
  mixins: [settingMixin],
  components: {
    PrismEditor,
  },
  computed: {
    id: {
      get() {
        return this.elementProps.id
      },
      set(id) {
        this.updateContent({id})
      }
    },
    classes: {
      get() {
        return this.elementProps.classes
      },
      set(classes) {
        this.updateContent({classes})
      }
    },
    html: {
      get() {
        return this.elementProps.html
      },
      set(html) {
        this.updateContent({html})
      },
    },
  },
  data () {
    return {
      SettingTitle: 'HTML區塊',
    }
  },
  methods: {
    updateContent(prop) {
      Object.keys(prop).forEach(key => {
        if (typeof prop[key] === 'string') {
          this.elementPropsSetter(prop)
        }
      })
    },
    highlighter(code) {
      return highlight(code, languages.js) // languages.<insert language> to return html with markup
    }
  }
}
</script>
