<template>
  <div :id="id" :class="classes">
    <iframe
      :width="width"
      :height="height"
      :src="url"
      title="YouTube video player" frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    />
    <div class="themeBuild-touch" />
  </div>
</template>

<script>
import YoutubeEmbedSetting from './YoutubeEmbedSetting.vue'

export default {
  props: {
    id: String,
    classes: String,
    url: {
      type: String,
      default: 'https://www.youtube.com/embed/3NycM9lYdRI'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '360'
    },
  },
  craft: {
    defaultProps: {
      id: '',
      classes: '',
      url: 'https://www.youtube.com/embed/3NycM9lYdRI',
      width: '100%',
      height: '360',
    },
    settings: {
      YoutubeEmbedSetting,
    }
  }
}
</script>
