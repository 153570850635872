<template>
  <div>
    <CInput :label="'ID'" size="sm" v-model="id" horizontal />
    <CInput :label="'Class'" size="sm" v-model="classes" horizontal />
    <CSelect horizontal :label="$t('Global.OpenTarget')" :options="TargetOption" size="sm" v-model="target" :value.sync="target" :placeholder="$t('Global.PleaseSelect')"/>
    <CInput :label="$t('Global.Link')" size="sm" v-model="href" horizontal />
  </div>
</template>

<script>
import { settingMixin } from '@v-craft/core'

export default {
  mixins: [settingMixin],
  computed: {
    id: {
      get() {
        return this.elementProps.id
      },
      set(id) {
        this.updateContent({id})
      }
    },
    classes: {
      get() {
        return this.elementProps.classes
      },
      set(classes) {
        this.updateContent({classes})
      }
    },
    target: {
      get() {
        return this.elementProps.target
      },
      set(target) {
        this.updateContent({target})
      },
    },
    href: {
      get() {
        return this.elementProps.href
      },
      set(href) {
        this.updateContent({href})
      },
    },
    TargetOption() {
      return [
        { value: '_self', label: this.$t('Global.SelfTarget')},
        { value: '_blank', label: this.$t('Global.BlankTarget')}
      ]
    }
  },
  data () {
    return {
      SettingTitle: '連結',
    }
  },
  methods: {
    updateContent(prop) {
      Object.keys(prop).forEach(key => {
        if (typeof prop[key] === 'string' || typeof prop[key] === 'boolean') {
          this.elementPropsSetter(prop)
        }
      })
    }
  }
}
</script>
