<template>
  <div class="position-relative">
    <div v-if="html" v-html="html" />
    <div v-else style="opacity: 0.33">
      請輸入HTML內容...
    </div>
    <div class="themeBuild-touch" />
  </div>
</template>

<script>
import HTMLSetting from './HTMLSetting.vue';

export default {
  props: {
    id: String,
    classes: String,
    html: String,
  },
  craft: {
    defaultProps: {
      id: '',
      classes: '',
      html: '<code>This is a html section.</code>',
    },
    settings: {
      HTMLSetting,
    }
  }
}
</script>
