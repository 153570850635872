<template>
  <CModal class="ActionModel" :show.sync="Toggle" :centered="true" size="lg" @update:show="HandleToggle">
    <div v-if="settings" class="settings">
      <component
        v-for="(component, name) in settings"
        ref="SettingPanel"
        :key="name"
        :is="component"
        :node="selectedNode"
       />
    </div>
    <CElementCover v-if="(Loading === true)" :opacity="0.75" class="py-5">
      <CSpinner color="info"/>
    </CElementCover>
    <template #header-wrapper>
      <header class="modal-header border-bottom-0">
        <h5 class="modal-title">組件設定: {{ currentPanelTitle }}</h5>
      </header>
    </template>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0">
        <CButton v-if="selectedNode && selectedNode.parent !== null" color="danger" @click="removeElement">{{ $t('Global.Delete') }}</CButton>
        <CButton class="ml-auto" @click="HandleToggle(false)" color="light">{{ $t('Global.Cancel') }}</CButton>
        <CButton @click="HandleToggle(false)" color="success">
          {{ $t('Global.Finish') }}
        </CButton>
      </footer>
    </template>
  </CModal>
</template>

<script>
export default {
  props: ['Toggle'],
  inject: [
    'editor',
  ],
  computed: {
    selectedNode() {
      return this.editor.selectedNode
    },
    settings() {
      if (!this.selectedNode) {
        return null
      }

      return this.editor.getSettings(this.selectedNode)
    },
  },
  data() {
    return {
      Loading: false,
      currentPanelTitle: '--'
    }
  },
  watch: {
    Toggle (value) {
      this.Loading = true
      setTimeout(() => {
        if (!value) {
          this.currentPanelTitle = '--'
          this.editor.selectNode(null)
        } else if (this.$refs.SettingPanel && this.$refs.SettingPanel.length > 0) {
          this.currentPanelTitle = this.$refs.SettingPanel[0].SettingTitle || '--'
        }
        this.Loading = false
      }, 500)
    },
    selectedNode (value) {
      if (value !== null) {
        this.$emit('update:Toggle', true)
      }
    }
  },
  methods: {
    removeElement() {
      this.HandleToggle(false)
      return this.editor.removeNode(this.selectedNode)
    },
    toggleState() {
      if (this.editor.enabled) {
        this.editor.disable()
      } else {
        this.editor.enable()
      }
    },
    HandleToggle(value) {
      this.$emit('update:Toggle', value)
    }
  }
}
</script>
