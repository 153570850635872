<template>
  <div>
    <CInput :label="'ID'" size="sm" v-model="id" horizontal />
    <CInput :label="'Class'" size="sm" v-model="classes" horizontal />
    <CSelect horizontal :label="'寬度'" :options="TypeOption" size="sm" v-model="type" :value.sync="type" :placeholder="$t('Global.PleaseSelect')"/>
  </div>
</template>

<script>
import { settingMixin } from '@v-craft/core'

export default {
  mixins: [settingMixin],
  computed: {
    id: {
      get() {
        return this.elementProps.id
      },
      set(id) {
        this.updateContent({id})
      }
    },
    classes: {
      get() {
        return this.elementProps.classes
      },
      set(classes) {
        this.updateContent({classes})
      }
    },
    type: {
      get() {
        return this.elementProps.type
      },
      set(type) {
        this.updateContent({type})
      },
    },
  },
  data () {
    return {
      SettingTitle: '容器',
      TypeOption: [
        {
          label: '預設',
          value: 'container'
        },
        {
          label: '滿版',
          value: 'container-fluid'
        },
        {
          label: '響應式容器(xl)',
          value: 'container-xl'
        },
        {
          label: '響應式容器(lg)',
          value: 'container-lg'
        },
        {
          label: '響應式容器(md)',
          value: 'container-md'
        },
        {
          label: '響應式容器(sm)',
          value: 'container-sm'
        }
      ]
    }
  },
  methods: {
    updateContent(prop) {
      Object.keys(prop).forEach(key => {
        if (typeof prop[key] === 'string') {
          this.elementPropsSetter(prop)
        }
      })
    },
  }
}
</script>
