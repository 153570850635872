<template>
  <div>
    <CInput :label="'ID'" size="sm" v-model="id" horizontal />
    <CInput :label="'Class'" size="sm" v-model="classes" horizontal />
    <CSelect horizontal :label="'xSmall'" :options="colOption" size="sm" v-model="colClass" :value.sync="colClass" :placeholder="$t('Global.PleaseSelect')"/>
    <CSelect horizontal :label="'Small'" :options="colOption" size="sm" v-model="smColClass" :value.sync="smColClass" :placeholder="$t('Global.PleaseSelect')"/>
    <CSelect horizontal :label="'Medium'" :options="colOption" size="sm" v-model="mdColClass" :value.sync="mdColClass" :placeholder="$t('Global.PleaseSelect')"/>
    <CSelect horizontal :label="'Large'" :options="colOption" size="sm" v-model="lgColClass" :value.sync="lgColClass" :placeholder="$t('Global.PleaseSelect')"/>
    <CSelect horizontal :label="'xLarge'" :options="colOption" size="sm" v-model="xlColClass" :value.sync="xlColClass" :placeholder="$t('Global.PleaseSelect')"/>
  </div>
</template>

<script>
import { settingMixin } from '@v-craft/core'

export default {
  mixins: [settingMixin],
  computed: {
    id: {
      get() {
        return this.elementProps.id
      },
      set(id) {
        this.updateContent({id})
      }
    },
    classes: {
      get() {
        return this.elementProps.classes
      },
      set(classes) {
        this.updateContent({classes})
      }
    },
    colClass: {
      get() {
        return this.elementProps.colClass.replace('col', '')
      },
      set(colClass) {
        this.updateContent({colClass})
      }
    },
    smColClass: {
      get() {
        return this.elementProps.smColClass.replace('col-sm', '')
      },
      set(smColClass) {
        this.updateContent({smColClass})
      }
    },
    mdColClass: {
      get() {
        return this.elementProps.mdColClass.replace('col-md', '')
      },
      set(mdColClass) {
        this.updateContent({mdColClass})
      }
    },
    lgColClass: {
      get() {
        return this.elementProps.lgColClass.replace('col-lg', '')
      },
      set(lgColClass) {
        this.updateContent({lgColClass})
      }
    },
    xlColClass: {
      get() {
        return this.elementProps.xlColClass.replace('col-xl', '')
      },
      set(xlColClass) {
        this.updateContent({xlColClass})
      }
    },
  },
  data () {
    return {
      SettingTitle: '網格',
      colOption: [
        {
          label: '自動縮排',
          value: ''
        },
        {
          label: '每行六個',
          value: '-2'
        },
        {
          label: '每行四個',
          value: '-3'
        },
        {
          label: '每行三個',
          value: '-4'
        },
        {
          label: '每行二個',
          value: '-6'
        },
        {
          label: '每行一個',
          value: '-12'
        },
        {
          label: '無設定',
          value: null
        }
      ]
    }
  },
  methods: {
    updateContent(prop) {
      Object.keys(prop).forEach(key => {
        if (typeof prop[key] === 'string') {
          switch (key) {
            case 'colClass':
              prop[key] = `col${prop[key]}`
              break
            case 'smColClass':
              prop[key] = `col-sm${prop[key]}`
              break
            case 'mdColClass':
              prop[key] = `col-md${prop[key]}`
              break
            case 'lgColClass':
              prop[key] = `col-lg${prop[key]}`
              break
            case 'xlColClass':
              prop[key] = `col-xl${prop[key]}`
              break
          }
          this.elementPropsSetter(prop)
        } else if (prop[key] === null) {
          prop[key] = ''
          this.elementPropsSetter(prop)
        }
      })
    }
  }
}
</script>
