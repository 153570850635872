<template>
  <div :class="type" class="p-3">
    <slot>
      <CAlert color="secondary" :close-button="false" class="mb-0 EmptyNotify text-center">
        目前容器是空的，請從左方拖曳組件以新增內容。
      </CAlert>
    </slot>
  </div>
</template>

<script>
import ContainerSetting from './ContainerSetting.vue';

export default {
  props: {
    id: String,
    classes: String,
    type: String,
  },
  craft: {
    defaultProps: {
      id: '',
      classes: '',
      type: 'container',
    },
    settings: {
      ContainerSetting,
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  outline: 1px dashed gray;
}
.EmptyNotify {
  margin: 0 -15px;
}
</style>
