<template>
  <div>
    <CInput :label="'ID'" size="sm" v-model="id" horizontal />
    <CInput :label="'Class'" size="sm" v-model="classes" horizontal />
    <CSelect horizontal :label="'網格間隔'" :options="GuttersOption" size="sm" v-model="gutters" :value.sync="gutters" :placeholder="$t('Global.PleaseSelect')"/>
  </div>
</template>

<script>
import { settingMixin } from '@v-craft/core'

export default {
  mixins: [settingMixin],
  computed: {
    id: {
      get() {
        return this.elementProps.id
      },
      set(id) {
        this.updateContent({id})
      }
    },
    classes: {
      get() {
        return this.elementProps.classes
      },
      set(classes) {
        this.updateContent({classes})
      }
    },
    gutters: {
      get() {
        return this.elementProps.gutters
      },
      set(gutters) {
        this.updateContent({gutters})
      },
    },
  },
  data () {
    return {
      SettingTitle: '網格區塊',
      GuttersOption: [
        {
          label: '預設',
          value: true
        },
        {
          label: '無間隔',
          value: false
        }
      ]
    }
  },
  methods: {
    updateContent(prop) {
      Object.keys(prop).forEach(key => {
        if (typeof prop[key] === 'string' || typeof prop[key] === 'boolean') {
          this.elementPropsSetter(prop)
        }
      })
    }
  }
}
</script>
