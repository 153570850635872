<template>
  <div>
    <CInput :label="'ID'" size="sm" v-model="id" horizontal />
    <CInput :label="'Class'" size="sm" v-model="classes" horizontal />
    <CInput
      type="text"
      :label="$t('Global.ImageURL')"
      v-model="src"
      size="sm"
      horizontal
    >
      <template #append>
        <CButton type="button" size="sm" color="dark" @click="OpenMediaStore()">{{ $t('Global.Browse') }}</CButton>
      </template>
    </CInput>
    <CInput :label="$t('Global.Alt')" size="sm" v-model="alt" horizontal />
    <CInput :label="$t('Global.Title')" size="sm" v-model="title" horizontal />
    <CInput :label="$t('Global.Link')" size="sm" v-model="href" horizontal />
    <CSelect horizontal :label="$t('Global.OpenTarget')" :options="TargetOption" size="sm" v-model="target" :value.sync="target" :placeholder="$t('Global.PleaseSelect')"/>
    <MediaStore :Config="MediaStoreConfig" @OK="SetImages()"></MediaStore>
  </div>
</template>

<script>
import { settingMixin } from '@v-craft/core'

export default {
  mixins: [settingMixin],
  components: {
    MediaStore: () => import('@/components/public/Media')
  },
  computed: {
    id: {
      get() {
        return this.elementProps.id
      },
      set(id) {
        this.updateContent({id})
      }
    },
    classes: {
      get() {
        return this.elementProps.classes
      },
      set(classes) {
        this.updateContent({classes})
      }
    },
    src: {
      get() {
        return this.elementProps.src
      },
      set(src) {
        this.updateContent({src})
      }
    },
    alt: {
      get() {
        return this.elementProps.alt
      },
      set(alt) {
        this.updateContent({alt})
      }
    },
    title: {
      get() {
        return this.elementProps.title
      },
      set(title) {
        this.updateContent({title})
      }
    },
    href: {
      get() {
        return this.elementProps.href
      },
      set(href) {
        this.updateContent({href})
      }
    },
    target: {
      get() {
        return this.elementProps.target
      },
      set(target) {
        this.updateContent({target})
      }
    },
    TargetOption() {
      return [
        { value: '_self', label: this.$t('Global.SelfTarget')},
        { value: '_blank', label: this.$t('Global.BlankTarget')}
      ]
    }
  },
  data () {
    return {
      SettingTitle: '圖片',
      MediaStoreConfig: {
        Display: false,
        ChooseImageList: [],
        Multiple: false
      },
    }
  },
  methods: {
    updateContent(prop) {
      Object.keys(prop).forEach(key => {
        if (typeof prop[key] === 'string') {
          this.elementPropsSetter(prop)
        }
      })
    },
    OpenMediaStore() {
      this.MediaStoreConfig.ChooseImageList = []
      this.MediaStoreConfig.Display = !this.MediaStoreConfig.Display
      this.MediaStoreConfig.Multiple = false
    },
    SetImages () {
      if (this.$store.state.user.permission.StaticDomain) {
        this.src = this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList
      } else {
        this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
          this.src = URL
        })
      }
    }
  }
}
</script>
