<template>
  <div v-c-tooltip="'輪播'" class="carouselSection position-relative">
    <CCarousel arrows indicators animate :height="unit === 'auto' ? unit : height + unit" class="banner d-none d-md-block">
      <CCarouselItem v-for="(item, index) in carousel" :key="index">
        <div v-if="item.src === ''" class="bg-secondary h-100 d-flex align-items-center justify-content-center">
          <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
        </div>
        <template v-else>
          <template v-if="item.href !== ''">
            <a href="#" :data-href="item.href" :target="item.target">
              <img v-lazy="item.src" :alt="item.alt" :title="item.title" class="img-fluid">
            </a>
          </template>
          <template v-else>
            <img v-lazy="item.src" :alt="item.alt" :title="item.title" class="img-fluid">
          </template>
        </template>
      </CCarouselItem>
    </CCarousel>
    <CCarousel arrows indicators animate :height="unitMobile === 'auto' ? unitMobile : heightMobile + unitMobile" class="bannerMobile d-block d-md-none">
      <CCarouselItem v-for="(item, index) in carouselMobile" :key="index">
        <div v-if="item.src === ''" class="bg-secondary h-100 d-flex align-items-center justify-content-center">
          <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
        </div>
        <template v-else>
          <template v-if="item.href !== ''">
            <a href="#" :data-href="item.href" :target="item.target">
              <img v-lazy="item.src" :alt="item.alt" :title="item.title" class="img-fluid">
            </a>
          </template>
          <template v-else>
            <img v-lazy="item.src" :alt="item.alt" :title="item.title" class="img-fluid">
          </template>
        </template>
      </CCarouselItem>
    </CCarousel>
    <div class="themeBuild-touch" />
  </div>
</template>

<script>
import BannerSetting from './BannerSetting.vue'

export default {
  props: {
    id: String,
    classes: String,
    height: String,
    carousel: Array,
    unit: String,
    heightMobile: String,
    carouselMobile: Array,
    unitMobile: String
  },
  craft: {
    defaultProps: {
      id: '',
      classes: '',
      height: '500',
      unit: 'px',
      carousel: [
        {
          src: '',
          alt: '',
          title: '',
          href: '',
          target: '_self'
        }
      ],
      heightMobile: '500',
      unitMobile: 'px',
      carouselMobile: [
        {
          src: '',
          alt: '',
          title: '',
          href: '',
          target: '_self'
        }
      ],
    },
    settings: {
      BannerSetting
    }
  }
}
</script>

<style lang="scss">
.banner {
  .carousel-inner {
    height: 100%;
    .carousel-item {
      height: 100%;
      .AddImages {
        width: 200px;
      }
    }
  }
}
</style>
