<template>
  <div class="banner">
    <CInput :label="'ID'" size="sm" v-model="id" horizontal />
    <CInput :label="'Class'" size="sm" v-model="classes" horizontal />
    <CTabs fade addTabsWrapperClasses="tab-info">
      <CTab active>
        <template #title>
          <CIcon name="cil-screen-desktop" />
        </template>
        <CInput v-if="unit !== 'auto'" :label="$t('Global.Height')" size="sm" v-model="height" horizontal>
          <template #append-content>
            {{ unit }}
          </template>
        </CInput>
        <CSelect horizontal :label="$t('Global.Unit')" :options="UnitOption" size="sm" v-model="unit" :value.sync="unit" :placeholder="$t('Global.PleaseSelect')"/>
        <Draggable
          v-model="carousel"
          tag="div"
          v-bind="DragOptions"
          @start="DragStart"
          @end="DragEnd"
        >
          <CCard v-for="(item, index) in carousel" :key="index" class="mb-2">
            <CButton
              block
              color="dark"
              variant="ghost"
              class="text-left shadow-none card-header py-2 px-3"
              @click="(Accordion = Accordion === index ? false : index)"
            >
              <h6 class="m-0">輪播 {{ index + 1 }}</h6>
            </CButton>
            <CCollapse :show="Accordion === index">
              <CCardBody class="p-3">
                <CInput
                  type="text"
                  :label="$t('Global.ImageURL')"
                  v-model="item.src"
                  size="sm"
                  horizontal
                >
                  <template #append>
                    <CButton type="button" size="sm" color="dark" @click="OpenMediaStore('carousel', index)">{{ $t('Global.Browse') }}</CButton>
                  </template>
                </CInput>
                <CInput :label="$t('Global.Alt')" size="sm" v-model="item.alt" horizontal />
                <CInput :label="$t('Global.Title')" size="sm" v-model="item.title" horizontal />
                <CInput :label="$t('Global.Link')" size="sm" v-model="item.href" horizontal />
                <CSelect horizontal :label="$t('Global.OpenTarget')" :options="TargetOption" size="sm" v-model="item.target" :value.sync="item.target" :placeholder="$t('Global.PleaseSelect')"/>
                <CTextarea :label="$t('Global.Content')" size="sm" v-model="item.content" horizontal />
                <CButton color="danger" block @click="RemoveCarousel(index)">{{ $t('Global.Remove') }}</CButton>
              </CCardBody>
            </CCollapse>
          </CCard>
        </Draggable>
        <hr>
        <CButton color="success" block @click="AddCarousel()">{{ $t('Global.Add') }}</CButton>
      </CTab>
      <CTab>
        <template #title>
          <CIcon name="cil-screen-smartphone" />
        </template>
        <CInput v-if="unitMobile !== 'auto'" :label="$t('Global.Height')" size="sm" v-model="heightMobile" horizontal>
          <template #append-content>
            {{ unitMobile }}
          </template>
        </CInput>
        <CSelect horizontal :label="$t('Global.Unit')" :options="UnitOption" size="sm" v-model="unitMobile" :value.sync="unitMobile" :placeholder="$t('Global.PleaseSelect')"/>
        <Draggable
          v-model="carouselMobile"
          tag="div"
          v-bind="DragOptions"
          @start="DragStart"
          @end="DragEnd"
        >
          <CCard v-for="(item, index) in carouselMobile" :key="index" class="mb-2">
            <CButton
              block
              color="dark"
              variant="ghost"
              class="text-left shadow-none card-header py-2 px-3"
              @click="(Accordion = Accordion === index ? false : index)"
            >
              <h6 class="m-0">輪播 {{ index + 1 }}</h6>
            </CButton>
            <CCollapse :show="Accordion === index">
              <CCardBody class="p-3">
                <CInput
                  type="text"
                  :label="$t('Global.ImageURL')"
                  v-model="item.src"
                  size="sm"
                  horizontal
                >
                  <template #append>
                    <CButton type="button" size="sm" color="dark" @click="OpenMediaStore('carouselMobile', index)">{{ $t('Global.Browse') }}</CButton>
                  </template>
                </CInput>
                <CInput :label="$t('Global.Alt')" size="sm" v-model="item.alt" horizontal />
                <CInput :label="$t('Global.Title')" size="sm" v-model="item.title" horizontal />
                <CInput :label="$t('Global.Link')" size="sm" v-model="item.href" horizontal />
                <CSelect horizontal :label="$t('Global.OpenTarget')" :options="TargetOption" size="sm" v-model="item.target" :value.sync="item.target" :placeholder="$t('Global.PleaseSelect')"/>
                <CTextarea :label="$t('Global.Content')" size="sm" v-model="item.content" horizontal />
                <CButton color="danger" block @click="RemoveMobileCarousel(index)">{{ $t('Global.Remove') }}</CButton>
              </CCardBody>
            </CCollapse>
          </CCard>
        </Draggable>
        <hr>
        <CButton color="success" block @click="AddMobileCarousel()">{{ $t('Global.Add') }}</CButton>
      </CTab>
    </CTabs>
    <MediaStore :Config="MediaStoreConfig" @OK="SetImages(ChooseImageIndex)"></MediaStore>
  </div>
</template>

<script>
import { settingMixin } from '@v-craft/core'

export default {
  mixins: [settingMixin],
  components: {
    Draggable: () => import('vuedraggable'),
    MediaStore: () => import('@/components/public/Media')
  },
  computed: {
    DragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    id: {
      get() {
        return this.elementProps.id
      },
      set(id) {
        this.updateContent({id})
      }
    },
    classes: {
      get() {
        return this.elementProps.classes
      },
      set(classes) {
        this.updateContent({classes})
      }
    },
    height: {
      get() {
        return this.elementProps.height
      },
      set(height) {
        this.updateContent({height})
      }
    },
    unit: {
      get() {
        return this.elementProps.unit
      },
      set(unit) {
        this.updateContent({unit})
      }
    },
    carousel: {
      get() {
        return this.elementProps.carousel
      },
      set(carousel) {
        this.updateContent({carousel})
      }
    },
    heightMobile: {
      get() {
        return this.elementProps.heightMobile
      },
      set(heightMobile) {
        this.updateContent({heightMobile})
      }
    },
    unitMobile: {
      get() {
        return this.elementProps.unitMobile
      },
      set(unitMobile) {
        this.updateContent({unitMobile})
      }
    },
    carouselMobile: {
      get() {
        return this.elementProps.carouselMobile
      },
      set(carouselMobile) {
        this.updateContent({carouselMobile})
      }
    },
    TargetOption() {
      return [
        { value: '_self', label: this.$t('Global.SelfTarget')},
        { value: '_blank', label: this.$t('Global.BlankTarget')}
      ]
    }
  },
  data () {
    return {
      SettingTitle: '輪播',
      UnitOption: [
        { value: 'px', label: '像素(px)'},
        { value: '%', label: '百分比(%)'},
        { value: 'vh', label: '可視高度(vh)'},
        { value: 'vw', label: '可視寬度(vw)'},
        { value: 'auto', label: '自動'},
      ],
      MediaStoreConfig: {
        Display: false,
        ChooseImageList: [],
        Multiple: false
      },
      ChooseImageIndex: '',
      ChooseType: '',
      Accordion: null,
      Drag: false,
    }
  },
  methods: {
    updateContent(prop) {
      Object.keys(prop).forEach(key => {
        if (typeof prop[key] === 'string' || typeof prop[key] === 'object' || typeof prop[key] === 'number') {
          this.elementPropsSetter(prop)
        }
      })
    },
    AddCarousel () {
      this.carousel.push({
        src: '',
        alt: '',
        title: '',
        href: '',
        target: '_self',
        content: ''
      })
    },
    AddMobileCarousel () {
      this.carouselMobile.push({
        src: '',
        alt: '',
        title: '',
        href: '',
        target: '_self',
        content: ''
      })
    },
    RemoveCarousel (index) {
      this.carousel.splice(index, 1)
    },
    RemoveMobileCarousel (index) {
      this.carouselMobile.splice(index, 1)
    },
    OpenMediaStore(type, index) {
      this.MediaStoreConfig.ChooseImageList = []
      this.MediaStoreConfig.Display = !this.MediaStoreConfig.Display
      this.MediaStoreConfig.Multiple = false
      this.ChooseImageIndex = index
      this.ChooseType = type
    },
    SetImages (index) {
      if (this.$store.state.user.permission.StaticDomain) {
        this[this.ChooseType][index].src = this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList
      } else {
        this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
          this[this.ChooseType][index].src = URL
        })
      }
    },
    DragStart() {
      this.Drag = true
    },
    DragEnd() {
      this.Drag = false
      this.$emit('update:data', this.data)
    },
  }
}
</script>
