<template>
  <div v-c-tooltip="'網格'" :class="`${colClass} ${smColClass} ${mdColClass} ${lgColClass} ${xlColClass}`">
    <slot>
      <CAlert color="secondary" :close-button="false" class="mb-0 EmptyNotify text-center">
        提示：需要將網格放入網格區塊內才會正常排列。
      </CAlert>
    </slot>
  </div>
</template>

<script>
import GridSetting from './GridSetting.vue';

export default {
  props: {
    id: String,
    classes: String,
    colClass: String,
    smColClass: String,
    mdColClass: String,
    lgColClass: String,
    xlColClass: String
  },
  craft: {
    defaultProps: {
      id: '',
      classes: '',
      colClass: 'col-12',
      smColClass: 'col-sm-6',
      mdColClass: 'col-md-4',
      lgColClass: 'col-lg-3',
      xlColClass: 'col-xl-2'
    },
    settings: {
      GridSetting
    }
  }
}
</script>
<style lang="scss" scoped>
div {
  outline: 1px dashed gray;
}
.EmptyNotify {
  margin: 0 -15px;
}
</style>
