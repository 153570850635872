<template>
  <div class="p-3">
    <slot />
  </div>
</template>

<script>
import LinkSetting from './LinkSetting.vue';

export default {
  props: {
    id: String,
    classes: String,
    target: String,
    href: String,
  },
  craft: {
    defaultProps: {
      id: '',
      classes: '',
      target: '',
      href: '',
    },
    settings: {
      LinkSetting,
    }
  }
}
</script>
<style lang="scss" scoped>
div {
  outline: 1px dashed gray;
  display: inline-block;
}
</style>
