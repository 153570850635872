<template>
  <div class="p-3">
    <h2 class="text-center">{{ title }}</h2>
    <p class="text-center">{{ subTitle }}</p>
    <div class="posts">
      <div class="row">
        <div v-for="item in List" :key="item._id" class="col-12 col-sm-6 col-lg-4 mb-3">
          <div>
            <img v-lazy="item.Cover" class="img-fluid w-100" style="object-fit: cover;aspect-ratio: 4 / 3;" />
          </div>
          <div class="p-3">
            <h6 class="text-truncate text-dark">{{ $dayjs(item.CreateTime).format('YYYY-MM-DD') }}</h6>
            <h5 class="mb-2" style="color: #333;-webkit-line-clamp: 2;overflow: hidden;display: -webkit-box;-webkit-box-orient: vertical;">{{ item.Name }}</h5>
            <p>{{ item.Introduction }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PostsSetting from './PostsSetting.vue'

export default {
  props: {
    id: String,
    classes: String,
    title: String,
    subTitle: String,
    list: Array,
    style: String,
    filter: Object
  },
  craft: {
    defaultProps: {
      id: '',
      classes: '',
      title: '',
      subTitle: '',
      list: [],
      filter: {
        Slug: [],
        Categories: '',
        Tags: [],
        Tags_IN: [],
        Page: 1,
        PerPage: 12,
        Order: 'create_nto'
      },
      style: 'thumbnail'
    },
    settings: {
      PostsSetting,
    }
  },
  data() {
    return {
      List: []
    }
  },
  mounted() {
    this.GetList()
  },
  methods: {
    GetList() {
      let RequestQuery = {
        page: this.filter.Page,
        perPage: this.filter.PerPage,
        order: this.filter.Order,
        status: 1
      }
      if (this.filter.Categories) {
        RequestQuery.categories = this.filter.Categories
      }
      if (Array.isArray(this.filter.Tags_IN) && this.filter.Tags_IN.length) {
        RequestQuery.tags_IN = this.filter.Tags_IN.join(',')
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/content/list',
        method: 'get',
        params: RequestQuery
      }).then(({data}) => {
        this.List = data.list
      })
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.GetList()
      }
    }
  }
}
</script>
