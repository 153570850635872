<template>
  <div class="position-relative">
    <div v-if="content" v-html="content" class="ck-content" />
    <div v-else style="opacity: 0.33" class="ck-content">請輸入內容...</div>
    <div class="themeBuild-touch" />
  </div>
</template>

<script>
import ContentSetting from './ContentSetting.vue'

export default {
  props: {
    id: String,
    classes: String,
    content: String,
  },
  craft: {
    defaultProps: {
      id: '',
      classes: '',
      content: '每一個不曾起舞的日子，都是對生命的辜負。所謂高貴的靈魂，即對自己懷有敬畏之心。沒有可怕的深度，就沒有美麗的水面。謙遜基於力量，傲慢基於無能。信仰就是不想知道真相是什麼。人生沒有目的，只有過程，所謂的終極目的是虛無的。一個人知道自己為什麼而活，就可以忍受任何一種生活。但凡不能殺死你的，最終都會使你更強大。白晝之光，豈知夜色之深。'
    },
    settings: {
      ContentSetting,
    }
  }
}
</script>
