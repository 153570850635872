<template>
  <div class="img-block position-relative">
    <template v-if="href !== ''">
      <a href="#" :data-href="href" :target="target">
        <img v-lazy="src" :alt="alt" :title="title" class="img-fluid">
      </a>
    </template>
    <template v-else>
      <img v-lazy="src" :alt="alt" :title="title" class="img-fluid">
    </template>
    <div class="themeBuild-touch" />
  </div>
</template>

<script>
import ImageInsertSetting from './ImageInsertSetting.vue'

export default {
  props: {
    id: String,
    classes: String,
    src: String,
    alt: String,
    title: String,
    href: String,
    target: String
  },
  craft: {
    defaultProps: {
      id: '',
      classes: '',
      src: 'https://storage.ksong.tw/Uploads/no_image.jpg',
      alt: '',
      title: '',
      href: '',
      target: '_self'
    },
    settings: {
      ImageInsertSetting
    }
  }
}
</script>
