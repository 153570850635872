<template>
  <div>
    <CInput :label="'ID'" size="sm" v-model="id" horizontal />
    <CInput :label="'Class'" size="sm" v-model="classes" horizontal />
    <CSelect horizontal :label="'段落'" :options="TagOptions" size="sm" v-model="tag" :value.sync="tag" :placeholder="$t('Global.PleaseSelect')"/>
    <CSelect horizontal :label="'水平對齊'" :options="AlignOptions" size="sm" v-model="align" :value.sync="align" :placeholder="$t('Global.PleaseSelect')"/>
    <CTextarea :label="$t('Global.Content')" size="sm" v-model="content" horizontal rows="5"/>
  </div>
</template>

<script>
import { settingMixin } from '@v-craft/core'

export default {
  mixins: [settingMixin],
  computed: {
    id: {
      get() {
        return this.elementProps.id
      },
      set(id) {
        this.updateContent({id})
      }
    },
    classes: {
      get() {
        return this.elementProps.classes
      },
      set(classes) {
        this.updateContent({classes})
      }
    },
    content: {
      get() {
        return this.elementProps.content
      },
      set(content) {
        this.updateContent({content})
      },
    },
    tag: {
      get() {
        return this.elementProps.tag
      },
      set(tag) {
        this.updateContent({tag})
      },
    },
    align: {
      get() {
        return this.elementProps.align
      },
      set(align) {
        this.updateContent({align})
      },
    }
  },
  data () {
    return {
      SettingTitle: '段落',
      TagOptions: [
        { label: '段落(p)', value: 'p'},
        { label: '標題一(h1)', value: 'h1'},
        { label: '標題二(h2)', value: 'h2'},
        { label: '標題三(h3)', value: 'h3'},
        { label: '標題四(h4)', value: 'h4'},
        { label: '標題五(h5)', value: 'h5'},
        { label: '標題六(h6)', value: 'h6'}
      ],
      AlignOptions: [
        { label: '靠左', value: 'left'},
        { label: '置中', value: 'center'},
        { label: '靠右', value: 'right'}
      ]
    }
  },
  methods: {
    updateContent(prop) {
      this.elementPropsSetter(prop)
    }
  }
}
</script>
