<template>
  <div v-c-tooltip="'網格區塊'" class="row" :class="[{'no-gutters': !gutters}]">
    <slot>
      <div class="col-12">
        <CAlert color="secondary" :close-button="false" class="mb-0 EmptyNotify text-center">
          提示：網格區塊為網格使用，若需要放置其他內容，請拖曳「網格」至區塊內，若只需要單行內容，請刪除網格區塊後再新增「圖層」組件。
        </CAlert>
      </div>
    </slot>
  </div>
</template>

<script>
import RowSetting from './RowSetting.vue';

export default {
  props: {
    id: String,
    classes: String,
    gutters: Boolean,
  },
  craft: {
    defaultProps: {
      id: '',
      classes: '',
      gutters: true,
    },
    settings: {
      RowSetting,
    }
  }
}
</script>
<style lang="scss" scoped>
div {
  outline: 1px dashed gray;
}
</style>
