<template>
  <CButton type="button" color="dark">
    <i v-if="fontIcon !== ''" :class="fontIcon" />
    <template v-if="content">
      {{ content }}
    </template>
    <span style="opacity: 0.33" v-else>
      按鈕文字s
    </span>
  </CButton>
</template>

<script>
import ButtonSetting from './ButtonSetting.vue'

export default {
  props: {
    id: String,
    classes: String,
    content: String,
    fontIcon: String,
    link: String
  },
  craft: {
    defaultProps: {
      id: '',
      classes: '',
      fontIcon: '',
      content: 'Button Text',
      link: ''
    },
    settings: {
      ButtonSetting,
    }
  }
}
</script>
