<template>
  <div>
    <CInput :label="'ID'" size="sm" v-model="id" horizontal />
    <CInput :label="'Class'" size="sm" v-model="classes" horizontal />
    <CInput :label="'Font Icon'" size="sm" v-model="fontIcon" horizontal />
    <CInput :label="$t('Global.Link')" size="sm" v-model="link" horizontal />
    <CInput :label="$t('Global.Content')" size="sm" v-model="content" horizontal />
  </div>
</template>

<script>
import { settingMixin } from '@v-craft/core'

export default {
  mixins: [settingMixin],
  computed: {
    id: {
      get() {
        return this.elementProps.id
      },
      set(id) {
        this.updateContent({id})
      }
    },
    classes: {
      get() {
        return this.elementProps.classes
      },
      set(classes) {
        this.updateContent({classes})
      }
    },
    content: {
      get() {
        return this.elementProps.content
      },
      set(content) {
        this.updateContent({content})
      }
    },
    link: {
      get() {
        return this.elementProps.link
      },
      set(link) {
        this.updateContent({link})
      }
    },
    fontIcon: {
      get() {
        return this.elementProps.fontIcon
      },
      set(fontIcon) {
        this.updateContent({fontIcon})
      }
    }
  },
  data () {
    return {
      SettingTitle: '按鈕',
    }
  },
  methods: {
    updateContent(prop) {
      this.elementPropsSetter(prop)
    }
  }
}
</script>
