<template>
  <div class="p-3">
    <h2 class="text-center">{{ title }}</h2>
    <p class="text-center">{{ subTitle }}</p>
    <div class="products">
      <div class="row">
        <div v-for="item in List" :key="item._id" class="col-6 col-md-4 col-xl-3 mb-3">
          <img v-lazy="item.Cover" class="img-fluid"/>
          <div class="p-3 text-center">
            <h6 class="text-truncate text-danger">{{ item.HeaderText }}</h6>
            <h5 class="mb-2" style="color: #333;-webkit-line-clamp: 2;overflow: hidden;display: -webkit-box;-webkit-box-orient: vertical;">{{ item.Name }}</h5>
            <div class="d-flex justify-content-center font-lg">
              <span class="text-danger font-weight-bold mr-2">${{ item.Amount.Actual }}</span>
              <span class="text-dark" style="text-decoration: line-through">${{ item.Amount.Suggest }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductsSetting from './ProductsSetting.vue'

export default {
  props: {
    id: String,
    classes: String,
    title: String,
    subTitle: String,
    list: Array,
    style: String,
    filter: Object
  },
  craft: {
    defaultProps: {
      id: '',
      classes: '',
      title: '',
      subTitle: '',
      list: [],
      filter: {
        SeriesNum: [],
        Categories: '',
        Brand: '',
        Tags: [],
        Page: 1,
        PerPage: 12,
        Order: 'create_nto'
      },
      style: 'grid'
    },
    settings: {
      ProductsSetting
    }
  },
  data() {
    return {
      List: []
    }
  },
  mounted() {
    this.GetList()
  },
  methods: {
    GetList() {
      let RequestQuery = {
        page: this.filter.Page,
        perPage: this.filter.PerPage,
        order: this.filter.Order,
        status: 1
      }
      if (this.filter.Categories) {
        RequestQuery.category = this.filter.Categories
      }
      if (Array.isArray(this.filter.SeriesNum) && this.filter.SeriesNum.length) {
        RequestQuery.seriesNum = this.filter.SeriesNum.join(',')
      }
      if (Array.isArray(this.filter.Tags) && this.filter.Tags.length) {
        RequestQuery.tags = this.filter.Tags.join(',')
      }
      if (this.filter.Brand) {
        RequestQuery.brand = this.filter.Brand
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/product/list',
        method: 'get',
        params: RequestQuery
      }).then(({data}) => {
        this.List = data.list
      })
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.GetList()
      }
    }
  }
}
</script>
