<template>
  <div class="bg-secondary text-dark p-3 text-center">
    商品分類顯示區塊
  </div>
</template>

<script>
import ProductCategorySetting from './ProductCategorySetting.vue'

export default {
  props: {
    id: String,
    classes: String,
    list: Array,
    style: String,
    filter: Object
  },
  craft: {
    defaultProps: {
      id: '',
      classes: '',
      list: [],
      filter: {
        SeriesNum: [],
        Categories: '',
        Tags: [],
        Page: 1,
        PerPage: 12,
        Order: 'nto'
      },
      style: 'grid'
    },
    settings: {
      ProductCategorySetting
    }
  }
}
</script>
