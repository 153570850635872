<template>
  <div>
    <CInput :label="'ID'" size="sm" v-model="id" horizontal />
    <CInput :label="'Class'" size="sm" v-model="classes" horizontal />
    <CInput :label="'Youtube影片網址'" size="sm" v-model="url" horizontal />
    <CInput :label="'寬度'" size="sm" v-model="width" horizontal />
    <CInput :label="'高度'" size="sm" v-model="height" horizontal />
  </div>
</template>

<script>
import { settingMixin } from '@v-craft/core'

export default {
  mixins: [settingMixin],
  computed: {
    id: {
      get() {
        return this.elementProps.id
      },
      set(id) {
        this.updateContent({id})
      }
    },
    classes: {
      get() {
        return this.elementProps.classes
      },
      set(classes) {
        this.updateContent({classes})
      }
    },
    url: {
      get() {
        return this.elementProps.url && this.elementProps.url.replace('watch?v=', 'embed/')
      },
      set(url) {
        url = url.replace('watch?v=', 'embed/')
        this.updateContent({url})
      }
    },
    width: {
      get() {
        return this.elementProps.width
      },
      set(width) {
        this.updateContent({width})
      }
    },
    height: {
      get() {
        return this.elementProps.height
      },
      set(height) {
        this.updateContent({height})
      }
    }
  },
  data() {
    return {
      SettingTitle: 'Youtube影片'
    }
  },
  methods: {
    updateContent(prop) {
      Object.keys(prop).forEach(key => {
        if (typeof prop[key] === 'string' || typeof prop[key] === 'object' || typeof prop[key] === 'number') {
          this.elementPropsSetter(prop)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.col-form-label {
  font-size: 12px;
}
</style>
